import Image, { IMAGE_TYPES } from "components/UI/Image";
import classes from "./Query.module.css";

import { getChainMetaDataWithProfile } from "utils/misc";
import { CustomRow } from "components/UI/Components/Components";

const IconWithChain = ({
  src,
  chain,
  alt,
  imageType = IMAGE_TYPES.AVATAR,
  iconClassName = "",
  chainClassName = "",
  iconHeight = "16px",
  iconWidth = "16px",
  chainHeight = "8px",
  chainWidth = "8px",
  chainRightDistance = "0px",
  chainProfiles,
  showImageFullHeightWidth = false,
  chainBorderWidth = "1px",
  number,
}) => {
  let chainData;
  if (chain) {
    chainData = getChainMetaDataWithProfile({ chainId: chain, chainProfiles });
  }
  return (
    <div
      className={`${classes.icon_with_chain} ${iconClassName}`}
      style={{ height: iconHeight, width: iconWidth }}>
      <Image
        src={src}
        className={`${classes.search_option_icon} ${iconClassName}`}
        {...(showImageFullHeightWidth
          ? { style: { height: "100%", width: "100%" } }
          : {})}
        alt={alt}
        imageType={imageType}
      />
      {chainData && (
        <Image
          src={chainData.overlayIcon}
          className={`${classes.search_chain_icon} ${chainClassName}`}
          style={{
            height: chainHeight,
            width: chainWidth,
            right: chainRightDistance,
          }}
          alt={chainData.name}
        />
      )}
      {number && (
        <div
          className={classes.search_chain_icon}
          style={{
            height: chainHeight,
            width: chainWidth,
            background: "var(--text-2)",
            fontSize: "8px",
            fontWeight: "700",
            color: "var(--base)",
            border: `${chainBorderWidth} solid var(--base)`,
          }}>
          <CustomRow
            width="100%"
            height="100%"
            alignItems="center"
            justifyContent="center"
            overflowY="visible"
            border="2px solid var(--base)"
            borderRadius="100%">
            {number}
          </CustomRow>
        </div>
      )}
    </div>
  );
};

export default IconWithChain;
