import { createContext, useEffect, useState, useRef, useCallback } from "react";
import { uniq } from "lodash";
import {
  getCoingeckoSlugTokenPrices,
  getGeckoTerminalTokenPrices,
} from "api/price.api";
import { signedRequest } from "api/api";

export const TokenPrices = createContext({});

const TokenPricesProvider = (props) => {
  const [coingeckoSlugTokenPrices, setCoingeckoSlugTokenPrices] = useState({});
  const [pnlBalance, setPnlBalance] = useState(null);
  const [fetchingPnlBalance, setFetchingPnlBalance] = useState(false);
  const queuedCoingeckoTokens = useRef([]);
  const loadingCoingeckoTokens = useRef(false);

  const [geckoTerminalTokenPrices, setGeckoTerminalTokenPrices] = useState({});
  const queuedGeckoTerminalTokens = useRef([]);
  const loadingGeckoTerminalTokens = useRef(false);

  const getPnlBalance = useCallback(
    async (items) => {
      if (!items?.length) return;
      if (fetchingPnlBalance) return;
      const filteredItems = pnlBalance
        ? items.filter((it) => {
            const [chain, address, user] = it;
            if (
              pnlBalance?.balances?.[chain]?.[address]?.[user] !== undefined
            ) {
              return false;
            }
            return true;
          })
        : items;
      if (filteredItems?.length === 0) return;

      try {
        setFetchingPnlBalance(true);
        const rdata = await signedRequest({
          path: "/api/v4/get_bulk_current_token_balance_with_liquidity_check",
          bodyText: JSON.stringify({
            chain_token_user_address: filteredItems,
          }),
          method: "post",
        });
        const res = rdata.data.data;
        setPnlBalance((old) => {
          const newObj = {
            balances: {},
            liquidity: {},
          };
          const oldChains = old ? Object.keys(old?.balances) : [];
          const newChains = Object.keys(res.balances) || [];
          const chains = uniq(oldChains.concat(newChains));
          chains.forEach((chain) => {
            const oldLiq = old?.liquidity?.[chain] || {};
            const newLiq = res.liquidity?.[chain] || {};
            if (!newObj.balances?.[chain]) newObj.balances[chain] = {};
            if (!newObj.liquidity?.[chain]) newObj.liquidity[chain] = {};
            newObj.balances[chain] = {
              ...old?.balances?.[chain],
              ...res?.balances?.[chain],
            };
            newObj.liquidity[chain] = {
              ...oldLiq,
              ...newLiq,
            };
          });
          return newObj;
        });
        return;
      } catch (err) {
        console.error({ err });
      } finally {
        setFetchingPnlBalance(false);
      }
    },
    [pnlBalance, fetchingPnlBalance]
  );

  const checkForPnlBalanceAndLiquidity = useCallback(
    ({ chain_id, address, user }) => {
      if (!pnlBalance) return false;
      if (
        pnlBalance?.balances?.[chain_id]?.[address]?.[user] !== undefined &&
        pnlBalance?.balances?.[chain_id]?.[address]?.[user] !== 0 &&
        pnlBalance?.liquidity?.[chain_id]?.[address] !== undefined
      ) {
        return true;
      }
      return false;
    },
    [pnlBalance]
  );

  const refreshCoingeckoSlugTokenPrices = useCallback(
    async (extraSlugs) => {
      if (loadingCoingeckoTokens.current) return null;
      loadingCoingeckoTokens.current = true;
      let slugs = Object.keys(coingeckoSlugTokenPrices);
      if (extraSlugs?.length) {
        slugs = uniq(slugs.concat(extraSlugs));
      }
      try {
        const prices = await getCoingeckoSlugTokenPrices(slugs);
        if (prices)
          setCoingeckoSlugTokenPrices({
            ...coingeckoSlugTokenPrices,
            ...prices,
          });
      } catch (e) {
        console.error(e);
      }
      loadingCoingeckoTokens.current = false;
    },
    [coingeckoSlugTokenPrices]
  );

  const requestCoingeckoSlugTokenPrices = (slug) => {
    if (Array.isArray(slug)) {
      slug.forEach((s) => {
        if (!queuedCoingeckoTokens.current.includes(s))
          queuedCoingeckoTokens.current.push(s);
      });
    } else {
      if (!queuedCoingeckoTokens.current.includes(slug))
        queuedCoingeckoTokens.current.push(slug);
    }
    if (
      Object.keys(coingeckoSlugTokenPrices)?.length === 0 &&
      queuedCoingeckoTokens.current?.length > 0
    ) {
      refreshCoingeckoSlugTokenPrices(queuedCoingeckoTokens.current);
    }
  };

  const refreshGeckoTerminalTokenPrices = useCallback(
    async (extraSlugs) => {
      if (loadingGeckoTerminalTokens.current) return null;
      loadingGeckoTerminalTokens.current = true;

      let slugs = Object.keys(geckoTerminalTokenPrices);
      if (extraSlugs?.length) {
        slugs = uniq(slugs.concat(extraSlugs));
      }

      try {
        const poolsInfo = await getGeckoTerminalTokenPrices(slugs);
        let slugToPoolInfo = {};
        poolsInfo?.forEach((poolInfo) => {
          slugToPoolInfo[poolInfo.id] = poolInfo;
        });
        const hasPrices =
          poolsInfo?.length > 0 && Object.keys(slugToPoolInfo)?.length > 0;
        if (hasPrices)
          setGeckoTerminalTokenPrices({
            ...geckoTerminalTokenPrices,
            ...slugToPoolInfo,
          });
      } catch (e) {
        console.error(e);
      }
      loadingGeckoTerminalTokens.current = false;
    },
    [geckoTerminalTokenPrices]
  );

  const getTokenPrice = ({ slug, terminalPool, terminalNetwork }) => {
    if (slug) {
      return coingeckoSlugTokenPrices?.[slug]?.usd ?? null;
    } else {
      const poolSlug = `${terminalNetwork}_${terminalPool}`.toLowerCase();
      return (
        geckoTerminalTokenPrices?.[poolSlug]?.attributes
          ?.base_token_price_usd ?? null
      );
    }
  };

  // follow this pattern to add terminal token prices
  // slug = {network}_{address}
  // check final price based on base or quote, base_token_price_usd or quote_token_price_usd
  const requestGeckoTerminalTokenPrices = (slug) => {
    if (Array.isArray(slug)) {
      slug.forEach((s) => {
        if (!queuedGeckoTerminalTokens.current.includes(s.toLowerCase()))
          queuedGeckoTerminalTokens.current.push(s.toLowerCase());
      });
    } else {
      if (!queuedGeckoTerminalTokens.current.includes(slug.toLowerCase()))
        queuedGeckoTerminalTokens.current.push(slug.toLowerCase());
    }
    if (
      Object.keys(geckoTerminalTokenPrices)?.length === 0 &&
      queuedGeckoTerminalTokens.current?.length > 0
    ) {
      refreshGeckoTerminalTokenPrices(queuedGeckoTerminalTokens.current);
    }
  };

  useEffect(() => {
    const gotTokens = Object.keys(coingeckoSlugTokenPrices);
    queuedCoingeckoTokens.current = queuedCoingeckoTokens.current.filter(
      (slug) => !gotTokens.includes(slug)
    );
  }, [coingeckoSlugTokenPrices]);

  useEffect(() => {
    const gotTokens = Object.keys(geckoTerminalTokenPrices);
    queuedGeckoTerminalTokens.current =
      queuedGeckoTerminalTokens.current.filter(
        (slug) => !gotTokens.includes(slug)
      );
    const networkUpdateMap = {};
    gotTokens.forEach((s) => {
      const [network] = s.split("_");
      if (
        geckoTerminalTokenPrices[s].updatedAt >=
        (networkUpdateMap[network] || 0)
      ) {
        networkUpdateMap[network] = geckoTerminalTokenPrices[s].updatedAt;
      }
    });
  }, [geckoTerminalTokenPrices]);

  useEffect(() => {
    const interval = setInterval(() => {
      refreshCoingeckoSlugTokenPrices(queuedCoingeckoTokens.current);
    }, 45000);
    return () => clearInterval(interval);
  }, [queuedCoingeckoTokens, refreshCoingeckoSlugTokenPrices]);

  useEffect(() => {
    const interval = setInterval(() => {
      refreshGeckoTerminalTokenPrices(queuedGeckoTerminalTokens.current);
    }, 45000);
    return () => clearInterval(interval);
  }, [queuedGeckoTerminalTokens, refreshGeckoTerminalTokenPrices]);

  return (
    <TokenPrices.Provider
      value={{
        coingeckoSlugTokenPrices,
        requestCoingeckoSlugTokenPrices,
        geckoTerminalTokenPrices,
        requestGeckoTerminalTokenPrices,
        pnlBalance,
        getPnlBalance,
        fetchingPnlBalance,
        checkForPnlBalanceAndLiquidity,
        getTokenPrice,
      }}>
      {props.children}
    </TokenPrices.Provider>
  );
};

export default TokenPricesProvider;
