import mixpanel from "mixpanel-browser";
import * as Sentry from "@sentry/react";
import { v4 as uuid } from "uuid";
export const initialiseEventTracking = ({ identityDetails }) => {
  if (process.env.NODE_ENV === "development") {
    return;
  }
  const identityId = identityDetails?.identity?.id;
  if (identityId != null) {
    trackEvent("User Logged In", null);
    mixpanel.identify(identityId?.toString());
  }

  const oldPusherChannelId = localStorage.getItem("pusherChannelId");
  const getAddress = () => {
    return localStorage.getItem("userAddress");
  };
  mixpanel.people.set({
    name: identityDetails?.identity?.display_name,
    email: identityDetails?.identity?.socials?.email,
    address: getAddress(),
    "Internal User": identityDetails?.superuser,
    old_pusher_channel_id: oldPusherChannelId,
  });
  if (oldPusherChannelId) {
    localStorage.removeItem("pusherChannelId");
  }
};

export const mixpanelSetAddress = ({ address }) => {
  try {
    mixpanel?.people?.set({ address });
  } catch (err) {
    return;
  }
};

export const trackEvent = (eventName, properties) => {
  try {
    if (process.env.NODE_ENV !== "development") {
      mixpanel.track(eventName, { ...properties, platform: MIXPANEL_PLATFORM });
    }
  } catch (e) {
    Sentry.captureException(e, {
      extra: { eventName, properties, msg: "Couldnt track event" },
    });
    return;
  }
};

export const MIXPANEL_EVENTS = {
  AddressToDetails: "AddressToDetails",
  AuthorizeForm: "AuthorizeForm",
  AllBlocksPage: "AllBlocksPage",
  BlockDetailsPage: "BlockDetailsPage",
  ChainPage: "ChainPage",
  Feed: "Feed",
  GroupedActivityDetails: "GroupedActivityDetails",
  PostDetails: "PostDetails",
  GroupedActivityGallery: "GroupedActivityGallery",
  LoginPopup: "LoginPopup",
  NativeTokenDetails: "NativeTokenDetails",
  Screener: "Screener",
  SeeAllResults: "SeeAllResults",
  Trending: "Trending",
  TrendingDetailsWrapper: "TrendingDetailsWrapper",
  MobileDownloadFooter: "MobileDownloadFooter",
  ActivityDetails: "ActivityDetails",
  TransactionActivityDetails: "TransactionActivityDetails",
  Bookmarks: "Bookmarks",
  CollectionDetails: "CollectionDetails",
  Community: "Community",
  ContractPage: "ContractPage",
  EditContractPopup: "EditContractPopup",
  DevSummaryDetails: "DevSummaryDetails",
  NavigateExplorer: "NavigateExplorer",
  CoinDcxContent: "CoinDcxContent",
  CoinDcxRealIdSeAA: "CoinDcxRealIdSeAA",
  ConnectTwitter: "ConnectTwitter",
  ConnectWallet: "ConnectWallet",
  WaitlistCountPage: "WaitlistCountPage",
  WaitlistResultPage: "WaitlistResultPage",
  LandingPage: "LandingPage",
  NewsDetails: "NewsDetails",
  NftItemDetails: "NftItemDetails",
  NotificationsPage: "NotificationsPage",
  OnboardingManualSetup: "OnboardingManualSetup",
  OnboardingMobileDownloadStep: "OnboardingMobileDownloadStep",
  OnboardingStart: "OnboardingStart",
  OnboardingTweetAboutUs: "OnboardingTweetAboutUs",
  Profile: "Profile",
  "Profile/Activities": "Profile/Activities",
  "Profile/Portfolio": "Profile/Portfolio",
  "Profile/Wallets": "Profile/Wallets",
  ProtocolPage: "ProtocolPage",
  ReferFriends: "ReferFriends",
  Settings: "Settings",
  TrendingDetailsPage: "TrendingDetailsPage",
  PageNotFound: "PageNotFound",
  MessagingApp: "MessagingApp",
  OnboardingInfoSlider: "OnboardingInfoSlider",
  OnboardingConnectWallet: "OnboardingConnectWallet",
  OnboardingTalkToUs: "OnboardingTalkToUs",
  OnboardingWalletLoader: "OnboardingWalletLoader",
  OnboardingConnectSocials: "OnboardingConnectSocials",
  OnboardingWalletChecklist: "OnboardingWalletChecklist",
  OnboardingDownloadApp: "OnboardingDownloadApp",
  OnboardingTopProfiles: "OnboardingTopProfiles",
  OnboardingFriends: "OnboardingFriends",
  OnboardingInviteCode: "OnboardingInviteCode",
  OnboardingBioDetails: "OnboardingBioDetails",
  OnboardingConnectedWallets: "OnboardingConnectedWallets",
};

export const getUniqueId = () => {
  return uuid();
};

export const getTrackerDeviceId = () => {
  const localDeviceId = localStorage.getItem("deviceId");
  if (!localDeviceId) {
    const deviceId = localStorage.setItem("deviceId", getUniqueId());
    return deviceId;
  }
  return localDeviceId;
};

export const getTrackerSessionId = () => {
  const localSessionId = sessionStorage.getItem("sessionId");
  if (!localSessionId) {
    const sessionId = sessionStorage.setItem("sessionId", getUniqueId());
    return sessionId;
  }
  return localSessionId;
};

export const MIXPANEL_PLATFORM = "web";

export const TRACKING_EVENTS = {
  WALLET_CONNECT: {
    POPUP_SHOWN: "wallet_selection_popup_shown",
    WALLET_CONNECTED: "wallet_selection_connected",
    WALLET_CONNECT_STATUS: "wallet_selection_connection_status",
    POPUP_DISMISS: "wallet_selection_popup_dismiss",
  },
};
