import { signedRequest } from "./api";

export const getContractDetails = async ({ chain_id, address }) => {
  const response = await signedRequest({
    method: "post",
    path: "/api/v4/get_contract_details",
    bodyText: JSON.stringify({
      chain_id: chain_id,
      contract_address: address,
    }),
  });

  return response.data.data;
};
export const getChainIds = async () => {
  const response = await signedRequest({
    method: "get",
    path: "/api/v4/get_chain_ids",
  });

  return response.data.data;
};

export const getTopBuyersSellers = async ({
  token_address,
  chain_id,
  period_days,
}) => {
  const response = await signedRequest({
    method: "post",
    path: "/api/v4/get_top_buyers_sellers_of_token",
    bodyText: JSON.stringify({
      token_address,
      chain_id,
      period_days,
    }),
  });

  return response.data.data;
};

export const getTopHolders = async ({ token_address, chain_id }) => {
  const response = await signedRequest({
    method: "post",
    path: "/api/v4/get_top_holders_of_token",
    bodyText: JSON.stringify({
      token_address,
      chain_id,
    }),
  });

  return response.data.data;
};

export const getProtocolDetails = async ({ protocol }) => {
  const response = await signedRequest({
    method: "post",
    path: "/api/v4/get_protocol_details",
    bodyText: JSON.stringify({
      identifier: protocol,
    }),
  });

  return response.data.data;
};

export const getTokenMetadata = async ({ address, chainId }) => {
  if (!address || !chainId) return [];
  const resp = await signedRequest({
    method: "post",
    path: "/api/v4/get_token_metadata",
    bodyText: JSON.stringify({
      token_address: address,
      chain_id: chainId,
    }),
  });

  return resp?.data?.data;
};

export const getForContractHeader = async ({
  chain_id,
  address,
  forNative,
}) => {
  if (forNative) {
    return await getTokenMetadata({ address, chainId: chain_id });
  } else {
    return await getContractDetails({ chain_id, address });
  }
};

export const updateContract = async ({
  chainId,
  address,
  website,
  name,
  twitter,
}) => {
  const data = {
    chain_id: chainId,
    address: address,
    protocol_url: website,
    display_name: name,
    twitter: twitter,
  };
  const resp = await signedRequest({
    method: "post",
    path: "/api/0xppl/upsert_contract_details",
    bodyText: JSON.stringify(data),
  });
  return resp?.data?.data;
};

export const getChainActivities = async ({ chainId }) => {
  const response = await signedRequest({
    method: "get",
    path: `/api/v4/get_activities_for_chain?chain_id=${chainId}&skip_grouping=true`,
  });

  return response.data.data;
};

export const getChainBlocksInfo = async ({ chainId, chainType }) => {
  const response = await signedRequest({
    method: "get",
    path: `/api/v4/get_blocks_details_page?chain_type=${chainType}&chain_id=${chainId}`,
  });

  return response.data.data;
};

export const getExternalPageLinkForSwap = async ({
  chainId,
  buyToken,
  sellToken,
}) => {
  const response = await signedRequest({
    method: "get",
    path: `/api/v4/get_external_page_link_for_swap?chain_id=${chainId}&buy_token=${buyToken}&sell_token=${sellToken}`,
  });
  return response.data.data;
};
