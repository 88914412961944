import classes from "./GenericFallBackUI.module.css";
import { EMPTY_STATES } from "zxStyles/constants";

const GenericFallBackUI = ({
  containerClass,
  fullPage = false,
  text = "There seems to be an issue.",
  height = "100px",
  width = "100px",
}) => {
  let style = {};
  if (fullPage) {
    style = {
      height: "100vh",
      width: "100vw",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    };
  }

  return (
    <div className={`${containerClass} ${classes.container}`} style={style}>
      <img
        src={EMPTY_STATES.ERROR.light}
        alt="Error"
        style={{
          width,
          height,
        }}
      />
      <p style={{ fontSize: "13px", color: "var(--text-1)" }}>{text}</p>
    </div>
  );
};

export default GenericFallBackUI;
