import { useContext } from "react";
import useIdleTimer from "customHooks/useIdleTimer";
import { GlobalContext } from "contextStates/Global";

const IdleTimer = () => {
  const { pusher, channel } = useContext(GlobalContext);

  useIdleTimer({
    delay: 1000 * 60 * 30,
    onIdle: () => {
      if (channel) {
        pusher.disconnect();
      }
    },
  });
  return null;
};

export default IdleTimer;
