import React, { useContext, useEffect, useMemo } from "react";
import { BrowserRouter, useLocation } from "react-router-dom";
import "./Sass/App.scss";
import { HelmetProvider } from "react-helmet-async";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import ErrorBoundary from "./components/ErrorBoundary/ErrorBoundary";
import { GlobalContext } from "./contextStates/Global";
import FullPageLoader from "./shared/FullPageLoader/FullPageLoader";
import IdleTimer from "./components/IdleTimer/IdleTimer";
import ThemeContextProvider from "./contextStates/Theme.context";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import loadable from "@loadable/component";
import SnackbarPopUp from "./shared/Snackbar/Snackbar";
import AuthContextProvider from "./contextStates/AuthContext";
import GlobalContextProvider from "./contextStates/Global";
import LeftSideHeaderContextProvider from "./contextStates/LeftSideHeaderContext";
import {
  ConnectionProvider,
  WalletProvider,
} from "@solana/wallet-adapter-react";
import { clusterApiUrl } from "@solana/web3.js";
import { WalletConnectWalletAdapter } from "@solana/wallet-adapter-walletconnect";
import MessagingContextProvider from "contextStates/MessagingContext";
import TokenPricesProvider from "contextStates/TokenPrices";
import UserContextProvider from "contextStates/User";
require("@solana/wallet-adapter-react-ui/styles.css");

const usePageTracking = () => {
  const location = useLocation();

  useEffect(() => {
    window.gtag("event", "page_view", {
      page_path: location.pathname + location.search,
    });
  }, [location]);
};

const Routes = loadable(() => import("components/Routes/Routes"), {
  fallback: <FullPageLoader />,
});

function GoogleAnalytics() {
  usePageTracking();
  return null;
}

function App() {
  const { snackBarState } = useContext(GlobalContext);
  const queryClient = new QueryClient();
  const network = clusterApiUrl("mainnet-beta");

  // You can use PublicKey from the wallet to fetch or do stuff.
  const wallet = useMemo(() => new WalletConnectWalletAdapter(), []);

  useEffect(() => {
    if (navigator?.appVersion?.indexOf("Win") !== -1) {
      // Add "windows" class to html
      document.documentElement.classList.add("windows");
    }
    // else if (navigator?.appVersion?.indexOf("Macintosh") !== -1) {
    //   document.documentElement.classList.add("mac");
    // }
  }, []);
  return (
    <div className="App">
      <QueryClientProvider client={queryClient}>
        <ThemeContextProvider>
          <ErrorBoundary fullPage>
            <BrowserRouter>
              <GoogleAnalytics />
              <HelmetProvider>
                <GlobalContextProvider>
                  <AuthContextProvider>
                    <MessagingContextProvider>
                      <LeftSideHeaderContextProvider>
                        <IdleTimer />
                        {/* <ScrollHandler /> */}
                        <ConnectionProvider endpoint={network}>
                          <WalletProvider wallets={[wallet]} autoConnect>
                            <TokenPricesProvider>
                              <UserContextProvider>
                                <Routes />
                              </UserContextProvider>
                            </TokenPricesProvider>
                          </WalletProvider>
                        </ConnectionProvider>
                        <SnackbarPopUp snackBarState={snackBarState} />
                        {process.env.NODE_ENV !== "production" && (
                          <ReactQueryDevtools initialIsOpen={false} />
                        )}
                      </LeftSideHeaderContextProvider>
                    </MessagingContextProvider>
                  </AuthContextProvider>
                </GlobalContextProvider>
              </HelmetProvider>
            </BrowserRouter>
          </ErrorBoundary>
        </ThemeContextProvider>
      </QueryClientProvider>
    </div>
  );
}

export default App;
